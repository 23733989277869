import _, { get } from "lodash";
import { addYears, addDays } from "date-fns";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import dataYml from "/src/data.yaml";
import UploadVehicleImage from "/src/components/agent/checkout/vehicle/UploadVehicleImage";
import UploadVehicleDocument from "/src/components/agent/checkout/vehicle/UploadVehicleDocument";
import Alert from "/src/components/alert";
import {
  FormGroup,
  TextField,
  ButtonRadius,
  DatePickerField,
  Checkbox,
  ButtonCheckbox,
} from "~src/components/form";
import { AlertModal } from "/src/components/modal";
// import Preloading from "/src/components/loading/Preloading";
import EndOfYearModal from "/src/components/modal/warning/EndOfYearModal";

import CheckoutContext from "/src/context/Checkout";
import { FIRST_DATE_NEW_YEAR } from "~/src/helpers/date";
import { parseDate, dateFormat, toJsDate, addOneYear } from "/src/helpers/dateTime";
import axios from "/src/helpers/configAxios";
import { messages } from "/src/helpers/messages";
import { toCamelCaseKeys, convertFileListToArray } from "/src/helpers/helpers";
import { formatNumberWithCurrency } from "/src/helpers/formatNumber";

import driverIcon from "~/assets/driver-hero.svg";
import lineLogo from "~/assets/contact/logo-line.png";
import heroQrIcon from "~/assets/contact/hero-qr-line.jpg";
import mtiQrIcon from "~/assets/contact/mti-qr-line.jpg";
import { useRecoilValue } from "recoil";
import config from "~src/states/config";

const VehicleInfoForm = (props) => {
  const {
    orderId,
    quoteInfo,
    addOrRemoveCMI,
    handleToggleCMI,
    attachImagesStatus,
    vehicleDocuments,
    setPreloading,
    setAlert,
    includeWhTax,
    contract,
  } = props;

  const appConfig = useRecoilValue(config);
  const history = useHistory();
  const { t } = useTranslation();
  const { state: checkoutState, dispatch: dispatchCheckout } = useContext(CheckoutContext);
  const { vehicle_images } = quoteInfo[0];
  const isPolicyType_1 = quoteInfo[0].policy_type === "1";
  const policyType = get(quoteInfo, "[0].policy_type", "");

  const { maxVmiCoverageStartDate, maxCmiCoverageStartDate } = appConfig.motor;

  const { coverStartDate, addonStartDate } = useMemo(
    () => toCamelCaseKeys(props.details),
    [props.details]
  );

  const [state, setState] = useState({
    coverStartDate: toJsDate(coverStartDate),
    coverEndDate: addOneYear(toJsDate(coverStartDate)),
    addonStartDate: toJsDate(addonStartDate),
    addonEndDate: addOneYear(toJsDate(addonStartDate)),
  });

  const [isDisableNextYearSelectForProduct, setIsDisableNextYearSelectForProduct] = useState(false);
  const [isDisableNextYearSelectForAddon, setIsDisableNextYearSelectForAddon] = useState(false);

  const [errConfirm, setErrConfirm] = useState(false);
  // const [preloading, setPreloading] = useState(false);
  const [warningModalProduct, setWarningModalProduct] = useState(false);
  const [warningModalAddon, setWarningModalAddon] = useState(false);

  const [confirm, setConfirm] = useState({
    agent: !t("agentCheckboxConfirm"),
    condition: false,
  });

  const { register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    dispatchCheckout({
      type: "ADD",
      payload: {
        ...checkoutState,
        insured_category: props.insuredCategory,
        order: {
          include_wh_tax: includeWhTax,
          details: {
            ...props.details,
            plate_province: props.province,
            addon_start_date:
              props.details.addon_start_date === null
                ? props.details.cover_start_date
                : props.details.addon_start_date,
            addon_end_date:
              props.details.addon_end_date === null
                ? props.details.cover_end_date
                : props.details.addon_end_date,
          },
        },
      },
    });
  }, []);

  const postUpdateOrder = (data) => {
    const url = `/api/orders/${orderId}`;

    // TEST
    console.log("postUpdateOrder:", orderId, data);

    setPreloading(true);
    axios
      .patch(url, data)
      .then((response) => {
        setPreloading(false);
        history.push(`/checkout/${orderId}/summary`);
      })
      .catch((error) => {
        console.error(error);
        unstable_batchedUpdates(() => {
          setPreloading(false);
          setAlert(
            `ERROR: ${error.response?.data?.message || error.response?.data?.error}`,
            error.response?.data?.details
          );
        });
      });
  };

  const handleChangeField = (evt, name) => {
    const { value } = evt.target;
    (typeof value === "undefined" || value === null) &&
      console.warn(`Field [${name}] lost control: ${value} =>`, evt.target);

    dispatchCheckout({
      type: "ADD",
      payload: {
        ...checkoutState,
        order: {
          ...checkoutState.order,
          details: {
            ...checkoutState.order.details,
            [name]: value,
          },
        },
      },
    });
  };

  const handleChangePlateNumber = (evt, name) => {
    let { value } = evt.target;
    value = value.replace(/[^ก-ฮA-Za-z0-9]/gi, "");
    dispatchCheckout({
      type: "ADD",
      payload: {
        ...checkoutState,
        order: {
          ...checkoutState.order,
          details: {
            ...checkoutState.order.details,
            [name]: value,
          },
        },
      },
    });
  };

  const handleChangeFieldChassisNumber = (evt, name) => {
    let { value } = evt.target;
    value = value.replace(/[^A-Z0-9]/gi, "");
    value = value.toUpperCase();
    dispatchCheckout({
      type: "ADD",
      payload: {
        ...checkoutState,
        order: {
          ...checkoutState.order,
          details: {
            ...checkoutState.order.details,
            [name]: value,
          },
        },
      },
    });
  };

  const handleChangeFieldCoverDate = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      coverStartDate: toJsDate(value),
      coverEndDate: addOneYear(toJsDate(value)),
    });
  };

  const callBackValueCoverDate = (value) => {
    let isOpenModal = checkOpenWarningModal(value);
    if (isOpenModal) {
      setIsDisableNextYearSelectForProduct(true);
      setWarningModalProduct(true);
      resetProductCoverDate();
      return;
    }

    const coverEndDate = addYears(parseDate(value), 1);
    checkoutState.order.details.cover_end_date = dateFormat(coverEndDate);

    setState({
      ...state,
      coverStartDate: toJsDate(value),
      coverEndDate: addOneYear(toJsDate(value)),
    });
  };

  const handleChangeFieldCMIDate = (evt) => {
    const { value } = evt.target;
    setState({
      ...state,
      addonStartDate: toJsDate(value),
      addonEndDate: addOneYear(toJsDate(value)),
    });
  };

  const callBackValueCMIDate = (value) => {
    let isOpenModal = checkOpenWarningModal(value);
    if (isOpenModal) {
      setIsDisableNextYearSelectForAddon(true);
      setWarningModalAddon(true);
      resetAddonCoverDate();
      return;
    }

    const coverEndDate = addYears(parseDate(value), 1);
    checkoutState.order.details.addon_end_date = dateFormat(coverEndDate);
    setState({
      ...state,
      addonStartDate: toJsDate(value),
      addonEndDate: addOneYear(toJsDate(value)),
    });
  };

  const checkOpenWarningModal = (value) => {
    return (
      appConfig.appName !== "mti" &&
      policyType !== "cmi" &&
      contract !== "renew" &&
      new Date(value) >= FIRST_DATE_NEW_YEAR
    );
  };

  const handleCloseWarningModalProduct = () => {
    setIsDisableNextYearSelectForProduct(false);
    setWarningModalProduct(false);
    resetProductCoverDate();
  };

  const handleCloseWarningModalAddon = () => {
    setIsDisableNextYearSelectForAddon(false);
    setWarningModalAddon(false);
    resetAddonCoverDate();
  };

  const resetProductCoverDate = () => {
    const currentDate = new Date();
    setState({
      ...state,
      coverStartDate: toJsDate(dateFormat(currentDate)),
      coverEndDate: addOneYear(toJsDate(dateFormat(currentDate))),
    });
  };

  const resetAddonCoverDate = () => {
    const currentDate = new Date();
    setState({
      ...state,
      addonStartDate: toJsDate(dateFormat(currentDate)),
      addonEndDate: addOneYear(toJsDate(dateFormat(currentDate))),
    });
  };

  const handleChangeConfirm = (field) => {
    confirm[field] = !confirm[field];
    setConfirm(confirm);
  };

  const fnClose = (status) => {
    setErrConfirm(!status);
  };

  const onSubmit = (data, evt) => {
    evt.preventDefault();
    const notAllConFirmed = Object.values(confirm).some((e) => e === false);

    if (notAllConFirmed) {
      setErrConfirm(true);
      return;
    }
    setErrConfirm(false);

    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);

    convertFileListToArray(formData, "order", "vehicle_document");
    convertFileListToArray(formData, "order", "vehicle_image");
    postUpdateOrder(formData);
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        {warningModalProduct && (
          <EndOfYearModal
            nextStep={false}
            iconOne={driverIcon}
            iconTwo={lineLogo}
            iconQR={t("appName") == "hero" ? heroQrIcon : mtiQrIcon}
            open={warningModalProduct}
            onClose={handleCloseWarningModalProduct}
          />
        )}

        {warningModalAddon && (
          <EndOfYearModal
            nextStep={false}
            iconOne={driverIcon}
            iconTwo={lineLogo}
            iconQR={t("appName") == "hero" ? heroQrIcon : mtiQrIcon}
            open={warningModalAddon}
            onClose={handleCloseWarningModalAddon}
          />
        )}
        <div className="relative motor-info-page-card">
          <h2>กรอกข้อมูลรถยนต์</h2>
          <div className="row">
            <div className="col-md-6">
              <FormGroup label="ทะเบียนรถยนต์" htmlForLabel="order[details][plate_number]" require>
                <TextField
                  id="order[details][plate_number]"
                  name="order[details][plate_number]"
                  maxLength={10}
                  onChange={(evt) => handleChangePlateNumber(evt, "plate_number")}
                  value={checkoutState.order.details.plate_number}
                  inputRef={register(messages.required)}
                  error={
                    !!errors.order &&
                    !!errors.order.details &&
                    !!errors.order.details.plate_number &&
                    errors.order.details.plate_number.message
                  }
                />
                <span className="help-txt text-xs">ตัวอย่าง: กข1234 หรือ 1กข1234</span>
              </FormGroup>
              <FormGroup
                label="จังหวัดที่จดทะเบียน"
                htmlForLabel="order[details][plate_province]"
                require
              >
                <TextField
                  id="order[details][plate_province]"
                  name="order[details][plate_province]"
                  inputRef={register}
                  value={checkoutState.order.details.plate_province}
                  readOnly
                />
              </FormGroup>
              <FormGroup
                label="หมายเลขตัวถังรถยนต์(คัสซี)"
                htmlForLabel="order[details][chassis_number]"
                require
              >
                <TextField
                  id="order[details][chassis_number]"
                  name="order[details][chassis_number]"
                  maxLength={t("maxChassisNumber")}
                  onChange={(evt) => handleChangeFieldChassisNumber(evt, "chassis_number")}
                  field="chassis-number"
                  inputRef={register(messages.required)}
                  value={checkoutState.order.details.chassis_number}
                  error={
                    !!errors.order &&
                    !!errors.order.details &&
                    !!errors.order.details.chassis_number &&
                    errors.order.details.chassis_number.message
                  }
                />
                <span className="help-txt text-xs">ตัวอย่าง: MMTJJKK10FH006002</span>
              </FormGroup>
            </div>
            <div className="col-md-6">
              <div className="vehicle-registration-box">
                <div className="vehicle-registration-layout">
                  <div className="top">
                    {checkoutState.order.details.plate_number !== undefined &&
                    checkoutState.order.details.plate_number !== ""
                      ? checkoutState.order.details.plate_number
                      : ""}
                  </div>
                  <div className="bottom">{checkoutState.order.details.plate_province}</div>
                </div>
              </div>
            </div>
          </div>

          <h2>
            {quoteInfo[0].policy_type !== "cmi"
              ? "วันที่คุ้มครองประกันภัยรถยนต์ภาคสมัครใจ"
              : "วันที่คุ้มครองประกันภาคบังคับ(พ.ร.บ.)"}
          </h2>
          <div className="row">
            <div className="col-md-6">
              <FormGroup
                htmlForLabel="order[details][cover_start_date]"
                label="วันเริ่มความคุ้มครอง"
                require
              >
                <DatePickerField
                  type="text"
                  id="order[details][cover_start_date]"
                  name="order[details][cover_start_date]"
                  minDate={parseDate(dateFormat(new Date()))}
                  maxDate={parseDate(
                    dateFormat(
                      addDays(
                        new Date(),
                        quoteInfo[0].policy_type !== "cmi"
                          ? maxVmiCoverageStartDate
                          : maxCmiCoverageStartDate
                      )
                    )
                  )}
                  onChange={handleChangeFieldCoverDate}
                  callbackvalue={callBackValueCoverDate}
                  disableNextYearSelect={isDisableNextYearSelectForProduct}
                  inputRef={register({
                    ...messages.required,
                    ...messages.validateCoverageDate,
                  })}
                  error={errors.order?.details?.cover_start_date?.message}
                  value={dateFormat(state.coverStartDate)}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup label="วันสิ้นสุดความคุ้มครอง" require>
                <TextField
                  type="text"
                  value={dateFormat(state.coverEndDate)}
                  name="order[details][cover_end_date]"
                  inputRef={register}
                  readOnly
                />
              </FormGroup>
            </div>
          </div>
          <span className="block -mt-3" style={{ fontSize: "0.8rem" }}>
            หมายเหตุ : ระยะเวลาคุ้มครอง 1 ปี และไม่สามารถคุ้มครองย้อนหลังได้
          </span>

          {isPolicyType_1 && (
            <div className="row">
              <div className="col-12">
                <UploadVehicleImage
                  id="vehicle_image"
                  name="order[vehicle_image]"
                  radioName="order[car_inspection_type]"
                  appName={t("appName")}
                  attachImagesStatus={attachImagesStatus || t("defaultAttachedStatus")}
                  vehicleImages={vehicle_images}
                  errors={errors.order}
                  register={register}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-12">
              <UploadVehicleDocument
                id="vehicle_document"
                name="order[vehicle_document]"
                vehicleDocuments={vehicleDocuments}
                errors={errors.order}
                register={register}
              />
            </div>
          </div>
          {quoteInfo[0].policy_type !== "cmi" && (
            <>
              <h2>ต้องการซื้อ พ.ร.บ. หรือไม่ ?</h2>
              <div className="flex">
                <ButtonCheckbox
                  label={`พ.ร.บ. ${formatNumberWithCurrency(addOrRemoveCMI.addon_selling_price)}`}
                  className={{ label: "mb-4" }}
                  id="checked_addon"
                  name="checked_addon"
                  inputRef={register()}
                  onChange={() => handleToggleCMI(addOrRemoveCMI.checked_addon)}
                  active={addOrRemoveCMI.checked_addon}
                  checked={addOrRemoveCMI.checked_addon}
                  value={addOrRemoveCMI.checked_addon}
                />
                {props.vehicleType === "120" && (
                  <div>
                    <div className="px-3 py-3 bg-gray-200 border rounded border-secondary">
                      พ.ร.บ. เชิงพาณิชย์ (เร็วๆ นี้)
                    </div>
                  </div>
                )}
              </div>

              {addOrRemoveCMI.checked_addon && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup
                        htmlForLabel="order[details][addon_start_date]"
                        label="วันเริ่มความคุ้มครอง"
                        require
                      >
                        <DatePickerField
                          type="text"
                          id="order[details][addon_start_date]"
                          name="order[details][addon_start_date]"
                          minDate={parseDate(dateFormat(new Date()))}
                          maxDate={parseDate(
                            dateFormat(addDays(new Date(), maxCmiCoverageStartDate))
                          )}
                          onChange={handleChangeFieldCMIDate}
                          callbackvalue={callBackValueCMIDate}
                          disableNextYearSelect={isDisableNextYearSelectForAddon}
                          inputRef={register({
                            ...messages.required,
                            ...messages.validateCoverageDate,
                          })}
                          error={errors.order?.details?.addon_start_date?.message}
                          value={dateFormat(state.addonStartDate)}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup label="วันสิ้นสุดความคุ้มครอง" require>
                        <TextField
                          type="text"
                          value={dateFormat(state.addonEndDate)}
                          name="order[details][addon_end_date]"
                          inputRef={register}
                          readOnly
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <span className="block -mt-2" style={{ fontSize: "0.8rem" }}>
                    หมายเหตุ : ระยะเวลาคุ้มครอง 1 ปี และไม่สามารถคุ้มครองย้อนหลังได้
                  </span>
                </>
              )}
            </>
          )}
          <div className="mt-4 row" style={{ marginTop: "30px" }}>
            <div className="col-md-12">
              <p className="mb-3 text-primary">
                {props.vehicleType !== "120" && (
                  <strong>
                    ผลิตภัณฑ์นี้ใช้สำหรับรถยนต์ส่วนบุคคลเท่านั้น
                    ไม่รองรับรถยนต์ที่นำไปใช้ในเชิงพาณิชย์
                  </strong>
                )}
              </p>
              <div className="flex-row">
                <Checkbox
                  id="confirmation"
                  label="ข้าพเจ้า ยืนยันและยอมรับเงื่อนไขและข้อตกลง ข้อมูลที่ระบุข้างต้นเป็นความจริงและไม่ได้ซ่อนหรือเปิดเผยสัญญาที่เป็นเท็จใด ๆ ระหว่างข้าพเจ้าและบริษัท หลังจากที่ใบสมัครประกันนี้ได้รับการอนุมัติโดยบริษัทแล้วจะถือว่าถูกต้อง"
                  onChange={() => handleChangeConfirm("condition")}
                  value={confirm.condition}
                />
                {t("agentCheckboxConfirm") && (
                  <Checkbox
                    id="agent_confirmed"
                    name="agent_confirmed"
                    label={dataYml.order.agent_confirmed}
                    onChange={() => handleChangeConfirm("agent")}
                    value={confirm.agent}
                  />
                )}
              </div>
            </div>
          </div>
          {errConfirm ? <AlertModal fnClose={fnClose} /> : null}
          <hr className="mt-6" />
          <div className="row">
            <div className="w-full motor-info-page-actions space-between">
              <ButtonRadius component="link" to="./step-1" type="submit">
                ย้อนกลับ
              </ButtonRadius>
              <ButtonRadius type="submit" color="primary">
                บันทึกและดำเนินการต่อ
              </ButtonRadius>
            </div>
          </div>
        </div>
      </form>
      {/* {errorDetails && <Alert details={errorDetails} />} */}
    </>
  );
};

export default VehicleInfoForm;
