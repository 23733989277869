import React, { useState, useEffect } from "react";
import queryString from "query-string";
import _ from "lodash";
import { dateFormat } from "/src/helpers/dateTime";
import { ToastProvider, useToasts } from "react-toast-notifications";

import { urlStringify } from "/src/helpers/helpers";

import ReactPaginate from "react-paginate";
import Select from "react-select";
import UploadPolicy from "./UploadPolicy";
import Preloading from "/src/components/loading/Preloading";
import SectionHeader from "/src/components/layout/staff/SectionHeader";
import SearchForm from "/src/components/staff/SearchForm";
import { ButtonRadius } from "/src/components/form";
import ErrorPage from "/src/components/errorPage";
import PolicyTable from "./components/table";
import DownloadModal from "/src/components/modal/download";
import UploadOrderModal from "~src/components/modal/upload/UploadOrderModal";

import useFetch from "./hooks/useFetch";
import useHttp from "/src/hooks/useHttp";
import useInput from "/src/hooks/useInput";
import { useAuthContext } from "~src/context/Auth";
import DatePickerRange from "../../../components/datePickerRange/DatePickerRange";
import { attachmentStatusOptions, keywordSearchOptions } from "~src/helpers/options";

import "./policy.scss";

const BASE_URL = "/staffs/policies";
const PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const statusOptions = [
  { value: "issuing", label: "กำลังดำเนินการ" },
  { value: "waiting_approval", label: "รอยืนยันข้อมูล"},
  { value: "failed", label: "ส่งงานไม่ผ่าน" },
  { value: "waiting_inspection", label: "รอตรวจสภาพ" },
  { value: "waiting_policy", label: "รอออกกรมธรรม์" },
  { value: "success", label: "จัดส่งสำเนากรมธรรม์" },
  { value: "cancel", label: "ยกเลิกกรมธรรม์" },
];
const downloadPolicyExcelFormId = "downloadPolicyExcelForm";

const getDefaultStatus = (status, options) => {
  return _.filter(options, (o) => _.includes(status, o.value));
};

const PolicyList = (props) => {
  const { location } = props;
  const queryParams = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const { session } = useAuthContext();
  const { permissions } = session;
  const allowUploadExcel = permissions.some(
    (p) => p.slug === "ORDER" && p.action === "IMPORT"
  );

  const [queryStr] = useState(queryParams);

  const [fetchLoading, setFetchLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [statePolicyId, setStatePolicyId] = useState(0);
  const [objById, setObjById] = useState({});
  const [callBackFile, setCallBackFile] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(queryParams.page ?? DEFAULT_PAGE);
  const [isError, setIsError] = useState(false);
  const [isDownloadModalOpened, setIsDownloadModalOpened] = useState(false);
  const [isDownloadModalDone, setIsDownloadModalDone] = useState(false);
  const [downloadModalMessage, setDownloadModalMessage] = useState("");
  const [downloadEmail, setDownloadEmail] = useState("");

  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);
  const [uploadOrderFile, setUploadOrderFile] = useState(null);
  const [uploadResult, setUploadResult] = useState({});

  const [filterState, setFilterState] = useState({
    keyword: "",
    keywordType: [],
    status: [],
    attachmentStatus: [],
    dateFrom: queryParams.created_from ?? "",
    dateTo: queryParams.created_to ?? "",
  });

  const {
    value: search_value,
    bind: handleChangeSearch,
    reset,
  } = useInput(queryParams?.keyword ?? "");

  const { addToast } = useToasts();
  const url = `/api/policies?status=all&${urlStringify({
    page: DEFAULT_PAGE,
    per_page: PER_PAGE,
    ...queryParams,
  })}`;
  const { response, error, isLoading } = useFetch(url);
  const { post, get } = useHttp();

  useEffect(() => {
    const selectedStatus = queryParams.policy_status;
    const selectedAttachmentStatus = queryParams.attachment_status;
    const selectedKeywordType = queryParams.keyword_type;
    const { keyword } = queryParams;
    const status = getDefaultStatus(selectedStatus, statusOptions);
    const attachmentStatus = getDefaultStatus(selectedAttachmentStatus, attachmentStatusOptions)
    const keywordType = selectedKeywordType ? getDefaultStatus(selectedKeywordType, keywordSearchOptions) : [keywordSearchOptions.find(o => o.value == "all")]
    setFilterState({
      ...filterState,
      keyword,
      keywordType,
      status,
      attachmentStatus,
    });
  }, []);

  useEffect(() => {
    if (error) setIsError(true);

    if (!isLoading && !error) {
      const results = response?.content;
      const totalPolicies = response.total_count;
      const totalPages = response.total_pages;
      setTotalRecords(totalPolicies);
      setPageCount(totalPages);
      setPackages(results);
      setIsError(false);
    }
  }, [response, error, isLoading]);

  const getSearchParams = () => {
    const { keywordType, status, attachmentStatus, dateFrom, dateTo } = filterState;
    return {
      keyword: search_value,
      keyword_type: keywordType.map((s) => s.value),
      policy_status: status.map((s) => s.value),
      attachment_status: attachmentStatus.map((s) => s.value),
      created_from: dateFrom,
      created_to: dateTo,
      per_page: PER_PAGE,
      page: 1,
    };
  };

  const handleChangeKeywordType = (optionData) => {
    const keywordType = optionData ? [optionData] : [];
    setFilterState({ ...filterState, keywordType });
  }

  const handleChangeStatus = (optionData) => {
    const status = optionData ? [...optionData] : [];
    setFilterState({ ...filterState, status });
  };

  const handleChangeAttachmentStatus = (optionData) => {
    const attachmentStatus = optionData ? [...optionData] : [];
    setFilterState({ ...filterState, attachmentStatus });
  };

  const handleClickFilter = () => {
    performSearch();
  };

  const handleResendIssue = (id) => {
    setFetchLoading(true);
    post(`/api/policies/manual_issue`, {
      policy_id: id,
    }).then((res) => {
      const { success } = res.data;
      let status = "failed";
      if (success) {
        status = "issuing";
      }

      updatePackage(packages, status, id);
      setFetchLoading(false);
      setAlert(success);
    });
  };

  const handleCheckStatus = (id) => {
    setFetchLoading(true);
    get(`/api/policies/${id}/status`)
      .then((res) => {
        const { success, message } = res.data;

        if (success && message !== "error") {
          updatePackage(packages, message, id);
          const statusText = statusOptions.find((o) => o.value === message).label;
          addToast(
            <div>
              <h4>ดำเนินการตรวจสอบสถานะกรมธรรม์</h4>
              <p>งานอยู่ในสถานะ {statusText}</p>
            </div>,
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
        } else {
          addToast(
            <div>
              <h4>ดำเนินการตรวจสอบสถานะกรมธรรม์</h4>
              <p>ไม่สามารถตรวจสอบสถานะงานได้เนื่องจากไม่รองรับกรมธรรม์เก่า</p>
            </div>,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }

        setFetchLoading(false);
      })
      .catch((err) => {
        setFetchLoading(false);
        setAlert(err.message);
      });
  };

  const handleInspection = (id, status) => {
    setFetchLoading(true);
    post(`/api/policies/${id}/inspection`, {
      status,
    }).then((res) => {
      const { success } = res.data;
      let status = "failed";
      if (success) status = "waiting_policy";
      else status = "issuing";

      updatePackage(packages, status, id);
      setFetchLoading(false);
      setAlert(success);
    });
  };

  const handleOpenModalUploadPolicy = (policyId, objs) => {
    setCallBackFile(null);
    setObjById(objs);
    setOpenModal(true);
    setStatePolicyId(policyId);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const cbFile = (file, id) => {
    setCallBackFile(file);
    updatePackageFile(packages, "success", file, id);
    setAlert(true);
  };

  const handleClickSearch = () => {
    performSearch();
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") performSearch();
  };

  const handlePageClick = (e) => {
    const selectedPage = parseInt(e.selected) + 1;
    const { keyword } = filterState;
    const params = getSearchParams();
    params.keyword = keyword;
    params.page = selectedPage;
    const queries = urlStringify(params);
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const performSearch = () => {
    const queries = urlStringify(getSearchParams());
    window.location.href = `${BASE_URL}?${queries}`;
  };

  const onDateChange = (dateFrom, dateTo) => {
    setFilterState({
      ...filterState,
      dateFrom: dateFormat(dateFrom),
      dateTo: dateFormat(dateTo),
    });
  };

  const resetValueCallback = () => {
    setFilterState({
      ...filterState,
      dateFrom: null,
      dateTo: null,
    });
  };

  function updatePackage(packages, status, id) {
    const newArray = [...packages];
    const index = packages.findIndex((obj) => obj.id == id);
    newArray[index] = {
      ...newArray[index],
      status,
    };

    setPackages(newArray);
  }

  function updatePackageFile(packages, status, file, id) {
    const newArray = [...packages];
    const index = packages.findIndex((obj) => obj.id == id);
    newArray[index] = {
      ...newArray[index],
      status,
      attached: true,
      policy_url: file,
    };

    setPackages(newArray);
  }

  function setAlert(success) {
    if (success) {
      addToast(
        <div>
          <h4>ดำเนินการส่งใบคำขอประกันภัย</h4>
          <p>ตรวจสอบสถานะได้ที่ติดตามกรมธรรม์</p>
        </div>,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } else {
      addToast(
        <div>
          <h4>ดำเนินการส่งใบคำขอประกันภัย</h4>
          <p>{message}</p>
        </div>,
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const handleOpenDownloadModal = () => {
    setIsDownloadModalDone(false);
    setDownloadEmail("");
    setIsDownloadModalOpened(!isDownloadModalOpened);
  };

  const handleDownloadFile = (data) => {
    const params = getSearchParams();
    data.append("keyword", params.keyword);
    data.append("created_from", params.created_from);
    data.append("created_to", params.created_to);
    params.policy_status.forEach((status) => {
      data.append("policy_status[]", status);
    });
    setDownloadModalMessage("กำลังดำเนินการ...");
    setFetchLoading(true);
    setIsDownloadModalDone(true);
    postDownloadPolicies(data)
      .then(() => {
        setDownloadModalMessage("ดำเนินการเสร็จสิ้น");
        setFetchLoading(false);
      })
      .catch(() => {
        setDownloadModalMessage("พบปัญหาระหว่างดำเนินการ กรุณาลองใหม่ในภายหลัง");
        setFetchLoading(false);
      });
  };

  const postDownloadPolicies = (data) => {
    const url = `/api/policies/export_policies_excel`;
    return post(url, data);
  };

  function handleOpenUploadModal() {
    setUploadResult({});
    setUploadOrderFile(null);
    setIsUploadModalOpened(true);
  }

  const handleUploadFileSelected = (e) => {
    setUploadOrderFile(e.target.files[0]);
  }

  const handleUploadFile = () => {
    const url = `/api/orders/upload/standard`;
    const data = getExcelData();

    setIsUploadModalOpened(false);
    setFetchLoading && setFetchLoading(true);
    post(url, data)
      .then((response) => {
        setFetchLoading(false);
        setUploadResult(response.data);
        setIsUploadModalOpened(true);
      })
      .catch((error) => {
        setFetchLoading && setFetchLoading(false);
        setUploadResult({
          success: false,
          message: error.message
        });
        setIsUploadModalOpened(true);
      })
  }

  const getExcelData = () => {
    const formData = new FormData();
    
    if (uploadOrderFile !== undefined) {
      formData.append(`file`, uploadOrderFile);
    }
    return formData;
  };

  return (
    <div className="policy-page">
      {isError ? (
        <ErrorPage />
      ) : (
        <>
          <SectionHeader
            title="กรมธรรม์ทั้งหมด"
            breadcrumbs={[{ url: "#", label: "รายการซื้อขาย", current: true }]}
            searchComponent={
              <div className="flex flex-row">
                <Select
                  className="w-40 mr-2"
                  options={keywordSearchOptions}
                  onChange={handleChangeKeywordType}
                  value={filterState.keywordType}
                />
                <SearchForm
                  type="text"
                  placeholder={
                    filterState.keywordType == "all"
                      ? "ค้นหาชื่อลูกค้า, ทะเบียนรถ, หรือ เลขที่คำสั่งซื้อ"
                      : `ค้นหา`
                  }
                  {...handleChangeSearch}
                  handleClick={handleClickSearch}
                  handleKeyPress={handleKeyPressSearch}
                />
              </div>
            }
          />
          <hr />
          <div className="result-box-all">
            {totalRecords > 0 ? (
              <>
                {queryStr?.keyword ? (
                  <span>
                    คำค้นหา:
                    <strong style={{ padding: "0 5px" }}>"{queryStr.keyword}" พบ</strong>
                  </span>
                ) : null}
                <span>
                  {totalRecords > 20 ? (
                    <span>รายการทั้งหมด 20+ รายการ</span>
                  ) : (
                    <>
                      <span>ทั้งหมด</span>
                      <strong> {totalRecords}</strong> <span>รายการ</span>
                    </>
                  )}
                </span>
              </>
            ) : null}
          </div>

          <section className="filter-area">
            <div className="filter-area-box">
              <div className="">
                <label>
                  <span>สถานะ</span>
                </label>
                <Select
                  options={statusOptions}
                  onChange={handleChangeStatus}
                  value={filterState.status}
                  isMulti
                />
              </div>
              <div className="w-64">
                <label htmlFor="">วันที่ทำรายการ</label>
                <DatePickerRange
                  resetValueCallback={resetValueCallback}
                  onDateChange={onDateChange}
                  values={[filterState.dateFrom, filterState.dateTo]}
                />
              </div>
            </div>
            <div className="text-center col-12">
              <ButtonRadius color="secondary" onClick={handleClickFilter}>
                ค้นหา
              </ButtonRadius>
            </div>
          </section>

          <div className="w-full overflow-x-auto">
            <PolicyTable
              policies={packages}
              isLoading={isLoading}
              onDownloadExcel={handleOpenDownloadModal}
              onUploadExcel={allowUploadExcel && handleOpenUploadModal}
              onUploadPolicy={(policy) => {
                handleOpenModalUploadPolicy(policy.id, policy);
              }}
              onConfirmPolicy={(policy) => {
                handleInspection(policy.id, "approve");
              }}
              onApproveSendPolicy={(policy) => {
                handleResendIssue(policy.id);
              }}
              onResendPolicy={(policy) => {
                handleResendIssue(policy.id);
              }}
              onCheckStatusPolicy={(policy) => {
                handleCheckStatus(policy.id);
              }}
            />
          </div>

          <div>
            {openModal && (
              <UploadPolicy id={statePolicyId} obj={objById} cbClose={closeModal} cbFile={cbFile} />
            )}

            {fetchLoading && <Preloading />}
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel="Prev"
                nextLabel="Next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                initialPage={Number(currentPage) - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName="gm-paginate"
                subContainerClassName="pages pagination"
                activeClassName="active"
                disableInitialCallback={true}
              />
            )}
            <DownloadModal
              id={downloadPolicyExcelFormId}
              title="ดาวน์โหลดกรมธรรม์"
              open={isDownloadModalOpened}
              isDownloadDone={isDownloadModalDone}
              downloadMessage={downloadModalMessage}
              downloadEmail={downloadEmail}
              onClose={() => setIsDownloadModalOpened(false)}
              onDownload={handleDownloadFile}
              onDownloadEmailChanged={(e) => setDownloadEmail(e.target.value)}
            />
            <UploadOrderModal
              id="uploadOrdersExcelFormId"
              headerText="อัพโหลด Standard Order"
              bodyText="หมายเหตุ: รองรับชนิดของไฟล์เป็น .xls และ .xlsx เท่านั้น"
              buttonText="เพิ่ม Order"
              isOpen={isUploadModalOpened}
              data={uploadResult}
              onClose={() => setIsUploadModalOpened(false)}
              onFileSelected={handleUploadFileSelected}
              onUpload={handleUploadFile}
            />
          </div>
        </>
      )}
    </div>
  );
};

const All = ({ location }) => {
  return (
    <ToastProvider placement="top-right">
      <PolicyList location={location} />
    </ToastProvider>
  );
};

export default All;
