import _ from "lodash";
import React from "react";

import Badges from "/src/components/badges";
import OrderDetail from "/src/components/orderDetail";
import ProductTypeIcon from "/src/components/ProductTypeIcon";

import yml from "/src/status.yml";
import dataYml from "/src/data.yaml";
import { defaultStaffOrdersAPIFactory } from "~src/api/staff/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { getInsuredByCategory, dateFormat } from "/src/helpers/helpers";
import { renderYaml } from "/src/helpers/renderYaml";
import useOrder from "/src/hooks/useOrder";
import "./Details.scss";

const PolicyStatus = ({ policies }) => {
  const vmiItems = policies.filter((p) => p.type !== "cmi");
  const cmiItems = policies.filter((p) => p.type === "cmi");

  return (
    <div className="flex">
      {vmiItems.length > 0 && (
        <div className="flex">
          <div className="mr-1">ชั้น {vmiItems[0].type}</div>
          <Badges status={`policy-${vmiItems[0].status}`}>
            {yml.status.policy.staff[vmiItems[0].status]}
          </Badges>
        </div>
      )}

      {cmiItems.length > 0 && (
        <div className="flex">
          <div className="mr-1">พ.ร.บ.</div>
          <Badges status={`policy-${cmiItems[0].status}`}>
            {yml.status.policy.staff[cmiItems[0].status]}
          </Badges>
        </div>
      )}
    </div>
  );
};

export default function Details(props) {
  const { match } = props;

  return (
    <PageContextProvider match={match}>
      <DetailsInner {...props} />
    </PageContextProvider>
  );
}

var DetailsInner = ({ match }) => {
  const { onError, setAlert } = usePageContext();
  const { isLoading, order, checkedAddon, installmentInfo, reload } = useOrder(match.params.id, {
    suffix: "info",
    apiFactory: defaultStaffOrdersAPIFactory,
    onError,
  });
  // const { ordersAPI } = useStaffOrdersAPI({
  //   onError: setAlert,
  // });

  const getShortInformation = () => {
    switch (order?.quotation?.product_type) {
      case "travel":
        return [
          "ข้อมูลการเดินทาง",
          `เดินทาง ${order.quotation?.short_desc_criteria} ไป ${order.quotation?.additional_information?.destination_name}`,
        ];
      default:
        return [
          "ข้อมูลรถยนต์ / รายละเอียดเบื้องต้น",
          `${order?.quotation?.criteria?.car_brand} ${order?.quotation?.criteria?.car_model}`,
        ];
    }
  };

  const handleUploadAttachment = () => {
    window.location.reload();
  }

  const shortInformation = getShortInformation();

  return (
    <PageContextProvider match={match}>
      {isLoading && <div className="agent-order-detail-loading">กำลังโหลด...</div>}
      {!isLoading && order && (
        <div className="agent-order-detail">
          <div className="card sticky">
            <div className="flex flex-row justify-between items-center">
              <div>
                <small>รายการคำสั่งซื้อ</small>
                <div className="flex align-items-center">
                  <h6 className="mr-2">
                    <ProductTypeIcon type={order.quotation?.productType} />
                    {order.number}{" "}
                  </h6>
                  <Badges status={`order-${status}`}>{yml.status.orders[status]}</Badges>
                  {order.status === "open" && (
                    <small>วันหมดอายุ: {dateFormat(order.expired_at)}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="agent-order-detail-sub-header mt-2">
              <div>
                <small>แผนประกันภัย</small>
                <div>
                  {order.quotes[0]?.addon ? (
                    <>
                      ชั้น {order.quotes[0].policy_type}
                      <span className="ml-1 inline-block">พ.ร.บ.</span>
                    </>
                  ) : (
                    <>
                      {renderYaml(
                        _.get(
                          dataYml.product_type,
                          `${order.quotation?.product_type}.${order.quotes[0]?.policy_type}`
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <small>ข้อมูลลูกค้า</small>
                <div>{getInsuredByCategory(order.insureds[0], order.company_name)}</div>
              </div>
              <div>
                <small>{shortInformation[0]}</small>
                <div>{shortInformation[1]}</div>
              </div>
              <div>
                <small>ตัวแทน</small>
                <div>
                 {order.owner_agent_role} - {order.agent.full_name} ({order.agent.number}) 
                </div>
              </div>
            </div>
          </div>

          <OrderDetail
            order={order}
            checkedAddon={checkedAddon}
            installmentInfo={installmentInfo}
            editable={true}
            uploadPaySlipAllowed={true}
            uploadPayerIDCardAllowed={true}
            ordersAPIFactory={defaultStaffOrdersAPIFactory}
            onReload={reload}
            onUploadAttachment={handleUploadAttachment}
            // onError={onError}
          />
        </div>
      )}
    </PageContextProvider>
  );
};
