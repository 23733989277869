import { format, parse, parseJSON, differenceInDays } from "date-fns";
import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ToastProvider, useToasts } from "react-toast-notifications";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useTranslation } from "react-i18next";
import { getTotalAmountDetails } from "~src/helpers/quotationHelper";
import ContinueOrderButton from "~src/components/form/button/ContinueOrderButton";

import Badges from "../badges";
import { Button, ButtonRadius } from "../form";
import SearchForm from "../form/SearchForm";
import ProductTypeIcon from "../icons/ProductTypeIcon";
import ShowPaySlipModal from "../modal/orders/showPayslipModal";
import PaymentsListView, {
  getPaymentDerivedFields,
  PaymentAction,
  PaymentActionsRenderer,
  PaymentMethodCell,
  PaymentsTableRowProps,
  PaySlipAction,
} from "../payments/PaymentsListView";
import { useAuthContext } from "~src/context/Auth";
import dataYml from "~src/data.yaml";
import statusYml from "~src/status.yml";
import { OrdersContextProvider, useOrdersContext } from "~src/context/Orders";
import { formatNumberWithCurrency, formatNumberWithCurrency2 } from "~src/helpers/formatNumber.js";
import { urlStringify, getInsuredByCategory } from "~src/helpers/helpers";
import { reformatDateEN } from "~src/helpers/date";
import { renderYaml } from "~src/helpers/renderYaml";
// import useInput from "~src/hooks/useInput";
import { FindOrdersParams } from "~src/models/params/FindOrdersParams";
import { Order } from "~src/types/order";
import { Payment } from "~src/types/payment";
import OrdersAPIClient from "~src/api/Orders";
import { useAuthenticate } from "~src/helpers/authen";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;
const EPSILON = 0.0001;

export type OrderAction = (order: Order) => void;
export type OrderActionsRenderer = (order: Order) => React.ReactNode;

export interface OrdersListViewProps {
  currentParams: FindOrdersParams;
  uploadPaySlipAllowed?: boolean;
  renderSectionHeader?: () => React.ReactNode;
  renderTableLayout?: (props: OrdersTableLayoutProps) => React.ReactNode;
  renderActions?: OrderActionsRenderer;
  ordersAPI?: OrdersAPIClient;
  onSearch?: (params: Partial<FindOrdersParams>) => void;
  onError?: (err: Error) => void;
  layoutProps?: Partial<OrdersTableLayoutProps>;
}

export default function OrdersListView({
  currentParams,
  uploadPaySlipAllowed,
  renderSectionHeader,
  renderTableLayout,
  renderActions,
  ordersAPI,
  onSearch,
  onError,
  layoutProps,
}: OrdersListViewProps) {
  const [currentPage, setCurrentPage] = useState(currentParams.page);

  const [isShowPaySlipModal, setIsShowPaySlipModal] = useState(false);
  const [paySlipURL, setPaySlipURL] = useState<string | undefined>();
  const [paySlipOrderID, setPaySlipOrderID] = useState<number | undefined>();
  const [paySlipPayment, setPaySlipPayment] = useState<Payment | undefined>();

  useEffect(() => {
    const { per_page } = currentParams;
    console.log("Pagination params changed:", { currentPage, per_page });
    onSearch && onSearch({ ...currentParams, page: currentPage });
  }, [currentPage]);

  function handleSetCurrentPage(nextPage: string | number) {
    // TEST
    console.log("handleSetCurrentPage:", nextPage);
    setCurrentPage(Number(nextPage));
  }

  function handleShowPaySlipModal(url: string | URL, orderID: number, payment?: Payment) {
    setPaySlipURL(typeof url === "string" ? url : url.toString());
    setPaySlipOrderID(orderID);
    setPaySlipPayment(payment);
    setIsShowPaySlipModal(true);
  }

  const handleClosePaySlipModal = () => {
    setIsShowPaySlipModal(false);
  };

  const tableLayoutProps = {
    ...layoutProps,
    initialPage: currentParams.page,
    setCurrentPage: handleSetCurrentPage,
    pageSize: currentParams.per_page,
    renderActions,
    onShowPaySlipModal: handleShowPaySlipModal,
  };

  return (
    <>
      {renderSectionHeader && renderSectionHeader()}
      <div className="view-order-title">
        <div className="title-bottom">
          <span className="gettmore-icon-info" />
          {` รายการคำสั่งซื้อจะมีอายุ 5 วัน นับตั้งแต่วันที่สร้างรายการ`}
        </div>
      </div>

      {renderTableLayout ? (
        <>{renderTableLayout(tableLayoutProps)}</>
      ) : (
        <OrdersListViewDefaultTableLayout {...tableLayoutProps} />
      )}

      {isShowPaySlipModal && (
        <ShowPaySlipModal
          payslip_url={paySlipURL}
          onClose={handleClosePaySlipModal}
          orderID={paySlipOrderID}
          editable={uploadPaySlipAllowed}
          payment={paySlipPayment}
          ordersAPI={ordersAPI}
        />
      )}
    </>
  );
}

export interface OrdersTableLayoutProps {
  initialPage?: number;
  pageSize?: number;
  setCurrentPage: (page: number) => void;
  renderActions?: OrderActionsRenderer;
  onShowPaySlipModal?: PaySlipAction;
  onCheckout?: OrderAction;
  rowProps?: Partial<OrdersTableHeaderRowProps>;
}

export var OrdersListViewDefaultTableLayout = ({
  initialPage,
  pageSize: _pageSize,
  setCurrentPage,
  renderActions,
  onShowPaySlipModal,
  rowProps,
}: OrdersTableLayoutProps) => {
  const { isLoading, orders, error, response: ordersResponse } = useOrdersContext();
  const {
    current_page: currentPage = initialPage || 1,
    total_pages: pagesCount = 0,
    total_count: _totalRecords = 0,
  } = ordersResponse || {};

  const handlePageClick = (e: any) => {
    console.log("handlePageClick:", e);
    const selectedPage = parseInt(e.selected) + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <div className="mt-4 view-order-component">
        <div className="table-grid">
          <OrdersListViewDefaultTableHeaderRow {...rowProps} />
          {isLoading && (
            <div
              className={`grid-tbody-full ${rowProps?.className || ""} ${
                rowProps?.cellClassName || ""
              }`}
            >
              กำลังโหลด...
            </div>
          )}
          {!isLoading && (
            <>
              {orders.length == 0 && (
                <div
                  className={`grid-tbody-full ${rowProps?.className || ""} ${
                    rowProps?.cellClassName || ""
                  }`}
                >
                  ไม่พบข้อมูล
                </div>
              )}
              {orders.map((order, index) => (
                <OrdersListViewDefaultTableRow
                  {...rowProps}
                  key={index}
                  order={order}
                  renderActions={renderActions}
                  onShowPaySlipModal={onShowPaySlipModal}
                />
              ))}
            </>
          )}
        </div>
      </div>

      {pagesCount > 1 && (
        <ReactPaginate
          previousLabel="Prev"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          initialPage={Number(currentPage) - 1}
          pageCount={pagesCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="gm-paginate"
          // DEPRECATED: https://github.com/AdeleD/react-paginate/issues/66
          // subContainerClassName={"pages pagination"}
          pageClassName="pages pagination"
          activeClassName="active"
          disableInitialCallback={true}
        />
      )}
    </>
  );
};

export interface OrdersTableHeaderRowProps extends React.ComponentPropsWithoutRef<"div"> {
  cellClassName?: string;
}

export var OrdersListViewDefaultTableHeaderRow = ({
  className,
  cellClassName,
  ...htmlProps
}: OrdersTableHeaderRowProps) => {
  const { t } = useTranslation();
  const { showPaidCommission } = useAuthenticate();

  return (
    <div {...htmlProps} className={`grid-th ${className || ""}`}>
      <div className={`${cellClassName || ""}`}>เลขที่คำสั่งซื้อ</div>
      <div className={`text-left ${cellClassName || ""}`}>ข้อมูลลูกค้า</div>
      <div className={`text-center ${cellClassName || ""}`}>ประเภทงาน</div>
      <div className={`${cellClassName || ""}`}>บริษัท</div>
      <div className={`text-center ${cellClassName || ""}`}>แผน</div>
      <div className={`${cellClassName || ""}`}>วันที่คุ้มครอง</div>
      <div className={`text-right ${cellClassName || ""}`}>ทุนประกัน</div>
      <div className={`text-right ${cellClassName || ""}`}>
        {showPaidCommission ? "ส่วนลดลูกค้า" : ""}
      </div>
      <div className={`text-right ${cellClassName || ""}`}>เบี้ยประกันที่ชำระ</div>
      <div className={`text-right ${cellClassName || ""}`}>
        {showPaidCommission ? "ค่าตอบแทนสุทธิ" : ""}
      </div>
      <div className={`text-center ${cellClassName || ""}`}>การชำระเงิน</div>
      <div />
    </div>
  );
};

export interface OrdersTableRowProps extends OrdersTableHeaderRowProps {
  order: Order;
  renderActions?: OrderActionsRenderer;
  onShowPaySlipModal?: PaySlipAction;
}

export var OrdersListViewDefaultTableRow = ({
  order,
  renderActions,
  onShowPaySlipModal,
  className,
  cellClassName,
  ...htmlProps
}: OrdersTableRowProps) => {
  const { t } = useTranslation();
  const [paymentRowsCollapsed, setPaymentRowsCollapsed] = useState(true);

  // TODO: Move into Orders Detail page
  // function handleShowGeneratePaymentLinkModal(payment: Payment) {
  //   console.log("TODO: Show Generate Payment link modal:", payment);
  // }

  return (
    <>
      <div {...htmlProps} className={`w-full grid-tbody h-20r ${className || ""}`}>
        <OrderStatusAndExpireDateCell order={order} className={cellClassName} />
        <OrderInsuredNameCell order={order} className={cellClassName} />
        <OrderContractTypeCell order={order} className={cellClassName} />
        <OrderInsurerInfoCell order={order} className={cellClassName} />
        <OrderProductInfoCell order={order} className={cellClassName} />
        <OrderCoverageDatesRangeCell order={order} className={cellClassName} />
        <OrderSumInsuredCell order={order} className={cellClassName} />
        <OrderDiscountCell order={order} className={cellClassName} />
        <OrderTotalAmountCell order={order} className={cellClassName} />
        <OrderEarningCommissionCell order={order} className={cellClassName} />
        <OrderPaymentsSummaryCell
          order={order}
          checkoutURL={`/checkout/${order.order_id}/step-1`}
          paymentRowsCollapsed={paymentRowsCollapsed}
          setPaymentRowsCollapsed={setPaymentRowsCollapsed}
          onShowPaySlipModal={onShowPaySlipModal}
          className={cellClassName}
        />
        <OrderActionsCell order={order} renderActions={renderActions} className={cellClassName} />
      </div>
      {!paymentRowsCollapsed && (
        <div className="grid-tbody-full justify-center text-center pl-2">
          <PaymentsListView
            payments={order.payments}
            order={order}
            // DEPRECATED: Move into Orders Detail page
            // renderActions={(payment) => (
            //   <OrderPaymentActions
            //     payment={payment}
            //     // onShowGeneratePaymentLinkModal={handleShowGeneratePaymentLinkModal}
            //   />
            // )}
            onShowPaySlipModal={onShowPaySlipModal}
            className="w-full mt-0 ml-2 mb-2 pl-2 border-l-2 border-gray-400 text-secondary-color"
            layoutProps={{ rowProps: { cellClassName: "text-secondary-color" } }}
          />
        </div>
      )}
    </>
  );
};

// TODO: Revise this later
export interface OrderStatusAndExpireDateCellProps extends OrdersTableRowProps {
  allowOrderAccess?: boolean;
}

export var OrderStatusAndExpireDateCell = ({ order, className = "", allowOrderAccess = false }: OrderStatusAndExpireDateCellProps) => {
  const { accessType } = useAuthenticate();
  const prefixPathOrderDetails = accessType !== "AGENT" ? "/staffs" : "";

  return (
    <div className={`flex flex-wrap content-start text-center ${className}`}>
      {(order.order_type !== "custom" || allowOrderAccess) ? (
        <>
          <ProductTypeIcon type={order.quotation.product_type} /> {` `}
          <Link
            to={`${prefixPathOrderDetails}/orders/${order.order_id}/details`}
            className="order-detail-link"
          >
            {`${order.number}`}
          </Link>
        </>
      ) : (
        <>
          <ProductTypeIcon type={order.quotation.product_type} />
          {` ${order.number}`}
        </>
      )}

      <br />
      <Badges status={`order-${order.status}`}>{statusYml.status.orders[order.status]}</Badges>
      {order.status === "open" && (
        <div className="block mt-2 expired_at">
          <ExpiredDateLabel
            label="วันหมดอายุ"
            diffDate={diffDate(order)}
            expiredAt={order.expired_at}
          />
        </div>
      )}
    </div>
  );
};

export var OrderInsuredNameCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start text-center">
      {getInsuredByCategory(order.insureds[0], order.insured_category)}
    </div>
  );
};

export var OrderContractTypeCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start text-center">{dataYml.order.contract[order.contract]}</div>
  );
};

export var OrderInsurerInfoCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex flex-col content-start text-center">
      {order.quotes[0]?.insurer_icon ? <Image icon={order.quotes[0].insurer_icon} /> : null}
      <p> </p>
    </div>
  );
};

export var OrderProductInfoCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start justify-center text-center">
      {!order.quotes[0] ? (
        "-"
      ) : (
        <>
          {renderYaml(
            _.get(
              dataYml,
              `product_type.${order.quotation.product_type}.${order.quotes[0].policy_type}`
            )
          )}
          {order.quotes[0].addon && (
            <>
              <br />
              พ.ร.บ.
            </>
          )}
        </>
      )}
    </div>
  );
};

export var OrderCoverageDatesRangeCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start text-justify">
      {order.quotes[0]?.coverage_from ? (
        <>
          {`เริ่ม: ${reformatDateEN(order.quotes[0].coverage_from)}`}
          <br />
          {`ถึง: ${reformatDateEN(order.quotes[0].coverage_to)}`}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export var OrderSumInsuredCell = ({ order, className }: OrdersTableRowProps) => {
  return (
    <div className="flex content-start justify-end text-end">
      {order.quotes[0] ? formatNumberWithCurrency2(order.quotes[0].sum_insured) : "-"}
    </div>
  );
};

export var OrderDiscountCell = ({ order, className }: OrdersTableRowProps) => {
  const { showPaidCommission } = useAuthenticate();
  return (
    <div className="flex content-start justify-end text-end">
      {showPaidCommission ?
        (order.quotes[0] ? formatNumberWithCurrency(order.quotes[0].discount) : "-")
        : ""
      }
    </div>
  );
};

export var OrderTotalAmountCell = ({ order, className }: OrdersTableRowProps) => {
  const totalAmountDetail = getTotalAmountDetails(
    order.quotation?.criteria,
    order.quotation?.product_type,
    order.payments_summary.payment_total_amount
  );
  return (
    <div className="flex content-start justify-end text-end text-primary">
      {formatNumberWithCurrency(order.payments_summary.payment_total_amount)}
      {totalAmountDetail && (
        <>
          <br />
          <div className="text-gray-500" style={{ fontSize: "10px" }}>
            {totalAmountDetail}
          </div>
        </>
      )}
    </div>
  );
};

export var OrderAttachmentStatusCell = ({ order, className }: OrdersTableRowProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex content-start justify-center text-center">
      {statusYml.attachmentStatus[order.installment_attachment_status]}
    </div>
  );
}

export var OrderEarningCommissionCell = ({ order, className }: OrdersTableRowProps) => {
  const { showPaidCommission } = useAuthenticate();
  return (
    <div className="flex content-start justify-end text-end">
      {showPaidCommission
        ? formatNumberWithCurrency(order.payments_summary.payment_earning_commission)
        : ""}
    </div>
  );
};

export interface OrderPaymentsSummaryCellProps extends OrdersTableRowProps {
  checkoutURL?: string | URL;
  paymentRowsCollapsed: boolean;
  setPaymentRowsCollapsed?: (state: boolean) => void;
  renderPaymentActions?: PaymentActionsRenderer;
}

export var OrderPaymentsSummaryCell = ({
  order,
  checkoutURL,
  paymentRowsCollapsed,
  setPaymentRowsCollapsed,
  onShowPaySlipModal,
  renderPaymentActions,
  className,
}: OrderPaymentsSummaryCellProps) => {
  const nPayments = order.payments?.length || 0;
  const totalPaidAmount = order.payments_summary.payment_total_paid_amount
    ? Number(order.payments_summary.payment_total_paid_amount)
    : 0;

  function handleToggleCollapseBtnClick(e: React.MouseEvent<HTMLElement>) {
    setPaymentRowsCollapsed(!paymentRowsCollapsed);
  }

  return (
    <div className={`flex flex-row content-start w-full ${className || ""}`}>
      {["open", "waiting_payment"].includes(order.status) &&
      (nPayments < 1 || totalPaidAmount < EPSILON) ? (
        <div className="h-fit w-fit">
          {checkoutURL && <ContinueOrderButton order={order} size="xs" />}
        </div>
      ) : nPayments > 1 ? (
        <div onClick={handleToggleCollapseBtnClick} className="w-full keep-words">
          <span className="float-left">
            {totalPaidAmount >= EPSILON ? (
              <>ชำระแล้ว {formatNumberWithCurrency(totalPaidAmount)}</>
            ) : null}
          </span>
          <ButtonRadius size="xxs" classNameOption="float-right">
            <i className="material-icons">{paymentRowsCollapsed ? "expand_more" : "expand_less"}</i>
          </ButtonRadius>
        </div>
      ) : nPayments === 1 ? (
        <div className="flex flex-col w-full">
          {totalPaidAmount >= EPSILON && (
            <p className="flex flex-row w-full">
              ชำระแล้ว {formatNumberWithCurrency(totalPaidAmount)}
            </p>
          )}
          {/* TODO: Revise the UI later (this is from master/Production) */}
          {order.order_type === "custom" ? (
            <>{statusYml.paymentType[order.payments[0].pay_method]}</>
          ) : (
            <PaymentMethodCell
              payment={order.payments[0]}
              order={order}
              renderActions={renderPaymentActions}
              onShowPaySlipModal={onShowPaySlipModal}
              // onShowGeneratePaymentLinkModal={onShow}
            />
          )}
          <p className="flex">&nbsp;</p>
        </div>
      ) : null}
    </div>
  );
};

export var OrderActionsCell = ({ order, renderActions, className }: OrdersTableRowProps) => {
  return (
    <div className={`order-actions ${className || ""}`}>
      {renderActions ? renderActions(order) : " "}
    </div>
  );
};

interface ExpiredDateLabelProps {
  label: string;
  diffDate: number;
  expiredAt: string | Date;
}

var ExpiredDateLabel = ({ label, diffDate, expiredAt }: ExpiredDateLabelProps) => {
  switch (true) {
    case diffDate === 0:
      return (
        <div className="text-red-600">
          {label} {reformatDateEN(expiredAt)}
          <br />
          <span>(พรุ่งนี้หมดอายุ)</span>
        </div>
      );
    case diffDate < 0:
      return (
        <div className="opacity-25">
          {label} {reformatDateEN(expiredAt)}
        </div>
      );
    default:
      return (
        <>
          {label} {reformatDateEN(expiredAt)}
        </>
      );
  }
};

var Image = ({ icon }) => {
  return <img className="insurer-img" src={icon} />;
};

function diffDate(order) {
  const now = new Date();
  return differenceInDays(order.expired_at || now, now);
}
