import _ from "lodash";
import React, { useCallback } from "react";
import { match as Match } from "react-router-dom";

import Badges from "~src/components/badges";
import ProductTypeIcon from "~src/components/ProductTypeIcon";
import OrderDetail from "~src/components/orderDetail";

import statusYml from "~src/status.yml";
import dataYml from "~src/data.yaml";
import { defaultAgentOrdersAPIFactory } from "~src/api/agent/Orders";
import { PageContextProvider, usePageContext } from "~src/context/Page";
import { reformatDateEN } from "~src/helpers/date";
import { getInsuredByCategory, dateFormat } from "~src/helpers/helpers";
import { renderYaml } from "~src/helpers/renderYaml";
import useOrder from "~src/hooks/useOrder";
import ContinueOrderButton from "~src/components/form/button/ContinueOrderButton";
import "./Details.scss";

export interface AgentOrderDetailsPageProps {
  match: Match<any>;
}

export default function AgentOrderDetailsPage(props: AgentOrderDetailsPageProps) {
  // TODO: Revise this later
  return (
    <PageContextProvider>
      <AgentOrderDetailsPageInner {...props} />
    </PageContextProvider>
  );
}

const ordersAPIFactory = (props) => new AgentOrdersAPIClient(props);

type AgentOrderDetailsPageInnerProps = AgentOrderDetailsPageProps;

const AgentOrderDetailsPageInner = ({ match }: AgentOrderDetailsPageInnerProps) => {
  const { id: orderId } = match.params;

  const { onError, setAlert } = usePageContext();
  const { isLoading, order, checkedAddon, installmentInfo, reload } = useOrder(orderId, {
    suffix: "info",
    apiFactory: defaultAgentOrdersAPIFactory,
    onError,
  });

  const getShortInformation = () => {
    switch (order?.quotation?.product_type) {
      case "travel":
        return [
          "ข้อมูลการเดินทาง",
          `เดินทาง ${order.quotation?.short_desc_criteria} ไป ${order.quotation?.additional_information?.destination_name}`,
        ];
      default:
        return [
          "ข้อมูลรถยนต์ / รายละเอียดเบื้องต้น",
          `${order?.quotation?.criteria?.car_brand} ${order?.quotation?.criteria?.car_model}`,
        ];
    }
  };

  const shortInformation = getShortInformation();

  const handleUploadAttachment = () => {
    window.location.reload();
  }

  return (
    <>
      {isLoading && <div className="agent-order-detail-loading">กำลังโหลด...</div>}
      {!isLoading && order && (
        <div className="agent-order-detail">
          <div className="card sticky">
            <div className="agent-order-detail-header">
              <div>
                <small>รายการคำสั่งซื้อ</small>
                <div className="flex align-items-center">
                  <h6 className="mr-2">
                    <ProductTypeIcon type={order.quotation?.product_type} />
                    {order.number}{" "}
                  </h6>
                  <Badges status={`order-${order.status}`}>
                    {statusYml.status.orders[order.status]}
                  </Badges>
                  {order.status === "open" && (
                    <small>วันหมดอายุ: {reformatDateEN(order.expired_at)}</small>
                  )}
                </div>
              </div>
              <div>
                <ContinueOrderButton order={order} />
              </div>
            </div>
            <div className="agent-order-detail-sub-header mt-2">
              <div>
                <small>แผนประกันภัย</small>
                <div>
                  {renderYaml(
                    _.get(
                      dataYml.product_type,
                      `${order.quotation.product_type}${order.quotes[0].policy_type}`
                    )
                  )}
                  {order.quotes[0].addon != undefined && (
                    <span className="ml-1 inline-block">พ.ร.บ.</span>
                  )}
                </div>
              </div>
              <div>
                <small>ข้อมูลลูกค้า</small>
                <div>{getInsuredByCategory(order.insureds[0], order.insured_category)}</div>
              </div>
              <div>
                <small>{shortInformation[0]}</small>
                <div>{shortInformation[1]}</div>
              </div>
              <div>
                <small>ตัวแทน</small>
                <div>
                  {order.agent?.full_name} ({order.agent?.number})
                </div>
              </div>
            </div>
          </div>

          <OrderDetail
            order={order}
            checkedAddon={checkedAddon}
            installmentInfo={installmentInfo}
            editable={false}
            uploadPaySlipAllowed={true}
            uploadPayerIDCardAllowed={true}
            ordersAPIFactory={defaultAgentOrdersAPIFactory}
            onReload={reload}
            onUploadAttachment={handleUploadAttachment}
            onError={onError}
          />
        </div>
      )}
    </>
  );
};
